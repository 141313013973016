import React, { useState, useCallback, useMemo } from "react";
import { Input, Progress } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { calculatePasswordStrength } from "../../utils/validation";

export default function PasswordInput({
  label,
  name,
  value,
  onValueChange,
  isInvalid,
  color,
  errorMessage,
  showStrengthMeter = true,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = useCallback(() => setIsVisible((prev) => !prev), []);

  const passwordStrength = useMemo(
    () => calculatePasswordStrength(value),
    [value],
  );

  const getStrengthText = (strength) => {
    if (strength <= 25) return "弱";
    if (strength <= 50) return "中等";
    if (strength <= 75) return "强";
    return "非常强";
  };

  const renderStrengthMeter = useCallback(
    () => (
      <div className="mt-2">
        <Progress
          size="sm"
          value={passwordStrength}
          color={
            passwordStrength > 75
              ? "success"
              : passwordStrength > 50
              ? "warning"
              : "danger"
          }
        />
        <p className="text-xs mt-1">
          密码强度：
          {passwordStrength > 75 ? "强" : passwordStrength > 50 ? "中等" : "弱"}
        </p>
      </div>
    ),
    [passwordStrength],
  );

  return (
    <div>
      <Input
        isRequired
        endContent={
          <button type="button" onClick={toggleVisibility}>
            <Icon
              className="pointer-events-none text-2xl text-default-400"
              icon={isVisible ? "solar:eye-closed-linear" : "solar:eye-bold"}
            />
          </button>
        }
        label={label}
        name={name}
        type={isVisible ? "text" : "password"}
        variant="bordered"
        value={value}
        onValueChange={onValueChange}
        isInvalid={isInvalid}
        color={color}
        errorMessage={errorMessage}
      />
      {showStrengthMeter && value && (
        <div className="mt-2">
          <Progress
            size="sm"
            value={passwordStrength}
            color={
              passwordStrength > 75
                ? "success"
                : passwordStrength > 50
                ? "warning"
                : "danger"
            }
          />
          <p className="text-xs mt-1">
            密码强度：{getStrengthText(passwordStrength)}
          </p>
        </div>
      )}
    </div>
  );
}
