export const validateEmail = (email) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})+$/;
  const localPart = email.split("@")[0];
  return (
    emailRegex.test(email) &&
    email.length <= 254 &&
    !email.includes("..") &&
    localPart.length <= 64
  );
};

export function validatePassword(password) {
  const minLength = 8;
  const maxLength = 128;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasNonAlphas = /\W/.test(password);

  return (
    password.length >= minLength &&
    password.length <= maxLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumbers &&
    hasNonAlphas
  );
}

export function calculatePasswordStrength(password) {
  if (password.length === 0 || password.trim().length === 0) return 0;

  let strength = 0;

  // 长度检查
  strength += Math.min(25, Math.floor((password.length / 8) * 25));

  // 复杂性检查
  if (/[A-Z]/.test(password)) strength += 25;
  if (/[a-z]/.test(password)) strength += 25;
  if (/\d/.test(password)) strength += 25;
  if (/\W/.test(password)) strength += 25;

  return Math.min(100, strength);
}
