import { Button } from "@nextui-org/react";
import { Icon } from "@iconify/react";

export default function SocialLoginButton({ provider, icon, onClick }) {
  return (
    <Button
      variant="bordered"
      startContent={<Icon icon={icon} />}
      onClick={onClick}
      className="w-full"
    >
      使用 {provider} 账号
    </Button>
  );
}
