"use client";

import React, { useReducer, useCallback, useMemo, useState } from "react";
import {
  Button,
  Input,
  Checkbox,
  Link,
  Divider,
  Tabs,
  Tab,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import PasswordInput from "./PasswordInput";
import SocialLoginButton from "./SocialLoginButton";
import { validateEmail, validatePassword } from "../../utils/validation";
import axios from "axios";
import { useRouter } from "next/navigation";

const API_BASE_URL =
  process.env.NEXT_PUBLIC_API_BASE_URL || "http://localhost:3000";

const initialState = {
  loginForm: { username: "", password: "" },
  signupForm: {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  },
};

function formReducer(state, action) {
  switch (action.type) {
    case "UPDATE_LOGIN_FORM":
      return {
        ...state,
        loginForm: { ...state.loginForm, [action.field]: action.value },
      };
    case "UPDATE_SIGNUP_FORM":
      return {
        ...state,
        signupForm: { ...state.signupForm, [action.field]: action.value },
      };
    default:
      return state;
  }
}

const useFormValidation = (form) => {
  const isInvalidUsername = useMemo(() => {
    if (form.username === "") return false;
    return form.username.length < 6 || form.username.length > 20;
  }, [form.username]);

  const isInvalidEmail = useMemo(() => {
    if (form.email === "") return false;
    return !validateEmail(form.email);
  }, [form.email]);

  const isInvalidPassword = useMemo(() => {
    if (form.password === "") return false;
    return !validatePassword(form.password);
  }, [form.password]);

  const isInvalidConfirmPassword = useMemo(() => {
    if (form.confirmPassword === "") return false;
    return form.password !== form.confirmPassword;
  }, [form.password, form.confirmPassword]);

  return {
    isInvalidUsername,
    isInvalidEmail,
    isInvalidPassword,
    isInvalidConfirmPassword,
  };
};

export default function SignUpLogin() {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const { loginForm, signupForm } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();

  const updateLoginForm = useCallback(
    (field, value) => dispatch({ type: "UPDATE_LOGIN_FORM", field, value }),
    [],
  );
  const updateSignupForm = useCallback(
    (field, value) => dispatch({ type: "UPDATE_SIGNUP_FORM", field, value }),
    [],
  );

  const {
    isInvalidUsername,
    isInvalidEmail,
    isInvalidPassword,
    isInvalidConfirmPassword,
  } = useFormValidation(signupForm);

  const handleLoginSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError("");
      try {
        const response = await axios.post(`${API_BASE_URL}/auth/login`, {
          username: loginForm.username,
          password: loginForm.password,
        });
        console.log("登录成功", response.data);
        localStorage.setItem("token", response.data.access_token);
      } catch (error) {
        console.error("登录失败", error.response?.data || error.message);
        setError(error.response?.data?.message || "登录失败，请稍后重试");
        onOpen(); // 打开错误提示模态框
      } finally {
        setIsLoading(false);
      }
    },
    [loginForm, router, onOpen],
  );

  const handleSignupSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        isInvalidUsername ||
        isInvalidEmail ||
        isInvalidPassword ||
        isInvalidConfirmPassword ||
        !signupForm.agreeTerms
      ) {
        setError("请检查表单填写是否正确");
        onOpen();
        return;
      }
      setIsLoading(true);
      setError("");
      try {
        const response = await axios.post(`${API_BASE_URL}/auth/register`, {
          username: signupForm.username,
          email: signupForm.email,
          password: signupForm.password,
        });
        console.log("注册成功", response.data);
        // 注册成功后自动登录
        const loginResponse = await axios.post(`${API_BASE_URL}/auth/login`, {
          username: signupForm.username,
          password: signupForm.password,
        });
        localStorage.setItem("token", loginResponse.data.access_token);
        router.push("/dashboard");
      } catch (error) {
        console.error("注册失败", error.response?.data || error.message);
        setError(error.response?.data?.message || "注册失败，请稍后重试");
        onOpen();
      } finally {
        setIsLoading(false);
      }
    },
    [
      isInvalidUsername,
      isInvalidEmail,
      isInvalidPassword,
      isInvalidConfirmPassword,
      signupForm,
      router,
      onOpen,
    ],
  );

  const handleSocialLogin = useCallback(
    async (provider) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/auth/${provider.toLowerCase()}`,
        );
        window.location.href = response.data.url; // 重定向到社交登录页面
      } catch (error) {
        console.error(
          `${provider} 登录失败`,
          error.response?.data || error.message,
        );
        setError(`${provider} 登录失败，请稍后重试`);
        onOpen();
      }
    },
    [onOpen],
  );

  const renderLoginForm = useMemo(
    () => (
      <form className="flex flex-col gap-3 mt-4" onSubmit={handleLoginSubmit}>
        <Input
          isRequired
          label="用户名或电子邮箱"
          name="username"
          type="text"
          variant="bordered"
          value={loginForm.username}
          onValueChange={(value) => updateLoginForm("username", value)}
          disabled={isLoading}
        />
        <PasswordInput
          label="密码"
          name="password"
          value={loginForm.password}
          showStrengthMeter={false}
          onValueChange={(value) => updateLoginForm("password", value)}
          disabled={isLoading}
        />
        <Button
          color="primary"
          type="submit"
          className="mt-2"
          isLoading={isLoading}
        >
          登录
        </Button>
      </form>
    ),
    [loginForm, handleLoginSubmit, updateLoginForm, isLoading],
  );

  const renderSignupForm = useMemo(
    () => (
      <form className="flex flex-col gap-3 mt-4" onSubmit={handleSignupSubmit}>
        <Input
          isRequired
          label="用户名"
          name="username"
          type="text"
          variant="bordered"
          value={signupForm.username}
          onValueChange={(value) => updateSignupForm("username", value)}
          isInvalid={isInvalidUsername}
          color={isInvalidUsername ? "danger" : "default"}
          errorMessage={
            isInvalidUsername ? "用户名长度必须在6到20个字符之间" : ""
          }
          disabled={isLoading}
        />
        <Input
          isRequired
          label="电子邮箱"
          name="email"
          type="email"
          variant="bordered"
          value={signupForm.email}
          onValueChange={(value) => updateSignupForm("email", value)}
          isInvalid={isInvalidEmail}
          color={isInvalidEmail ? "danger" : "default"}
          errorMessage={isInvalidEmail ? "请输入有效的电子邮箱地址" : ""}
          disabled={isLoading}
        />
        <PasswordInput
          label="密码"
          name="password"
          value={signupForm.password}
          onValueChange={(value) => updateSignupForm("password", value)}
          isInvalid={isInvalidPassword}
          color={isInvalidPassword ? "danger" : "default"}
          errorMessage={
            isInvalidPassword
              ? "密码必须至少包含8个字符，并包含大小写字母、数字和特殊字符"
              : ""
          }
          disabled={isLoading}
        />
        <PasswordInput
          label="确认密码"
          name="confirmPassword"
          value={signupForm.confirmPassword}
          onValueChange={(value) => updateSignupForm("confirmPassword", value)}
          isInvalid={isInvalidConfirmPassword}
          color={isInvalidConfirmPassword ? "danger" : "default"}
          errorMessage={isInvalidConfirmPassword ? "密码和确认密码不匹配" : ""}
          showStrengthMeter={false}
          disabled={isLoading}
        />
        <div>
          <Checkbox
            isRequired
            className="py-4"
            size="sm"
            isSelected={signupForm.agreeTerms}
            onValueChange={(value) => updateSignupForm("agreeTerms", value)}
            disabled={isLoading}
          >
            我同意&nbsp;
            <Link href="#" size="sm">
              服务条款
            </Link>
            &nbsp;和&nbsp;
            <Link href="#" size="sm">
              隐私政策
            </Link>
          </Checkbox>
        </div>
        <Button color="primary" type="submit" isLoading={isLoading}>
          注册
        </Button>
      </form>
    ),
    [
      signupForm,
      handleSignupSubmit,
      updateSignupForm,
      isInvalidUsername,
      isInvalidEmail,
      isInvalidPassword,
      isInvalidConfirmPassword,
      isLoading,
    ],
  );

  return (
    <div className="flex h-full w-full items-center justify-center light">
      <div className="flex w-full max-w-sm flex-col gap-4 rounded-large bg-content1 px-8 pb-10 pt-6 shadow-small">
        <Tabs fullWidth size="md" defaultSelectedKey="login">
          <Tab key="login" title="登录">
            {renderLoginForm}
          </Tab>
          <Tab key="signup" title="注册">
            {renderSignupForm}
          </Tab>
        </Tabs>

        <div className="flex items-center gap-4 py-2">
          <Divider className="flex-1" />
          <p className="shrink-0 text-tiny text-default-500">或</p>
          <Divider className="flex-1" />
        </div>

        <div className="flex flex-col gap-2">
          <SocialLoginButton
            provider="Google"
            icon="flat-color-icons:google"
            onClick={() => handleSocialLogin("Google")}
            disabled={isLoading}
          />
          <SocialLoginButton
            provider="Github"
            icon="fe:github"
            onClick={() => handleSocialLogin("Github")}
            disabled={isLoading}
          />
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader>错误</ModalHeader>
          <ModalBody>
            <p>{error}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onPress={onClose}>
              关闭
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
